import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal as MaterialUIModal, Fade, Backdrop } from "@material-ui/core";

interface ModalProps {
  children: React.ReactNode;
  onClose: (status: string) => void;
  showModal: boolean;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 825,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Modal = (props: ModalProps) => {
  const classes = useStyles();

  return (
    <MaterialUIModal
      className={classes.modal}
      open={props.showModal}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.showModal}>
        <div className={classes.paper}>{props.children}</div>
      </Fade>
    </MaterialUIModal>
  );
};

export default Modal;
