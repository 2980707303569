import React, { useState, useEffect } from "react";
import DetailsModal from "../../components/DetailsModal";
import { IuserMgtAccess } from "./interface";

const UserMgtModal: React.FC<IuserMgtAccess> = (props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(true);

  useEffect(() => {
    setShowSuccessModal(props.showModal ?? false);
  }, [props.showModal]);

  return (
    <>
      <DetailsModal
        showModal={showSuccessModal}
        onClose={(): void => {
          props.onClose();
        }}
        onCloseRedirect={(): void => {
          props.onCloseRedirect();
        }}
        uid={props.uid ?? ""}
      />
    </>
  );
};

export default UserMgtModal;
