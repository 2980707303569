import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema.json";

export const getFullName1 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client1?.fName} ${client.personalInfo.client1?.mName} ${client.personalInfo.client1?.lName}`;

export const getFirstName1 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client1?.fName}`;

export const getMiddleName1 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client1?.mName}`;

export const getDOB1 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client1?.dob}`;

export const getMphone1 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client1?.mPhone}`;

export const getEmail1 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client1?.email}`;

export const getMarital1 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client1?.marital}`;

export const getFullName2 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client2?.fName} ${client.personalInfo.client2?.mName} ${client.personalInfo.client2?.lName}`;

export const getFirstName2 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client2?.fName}`;

export const getMiddleName2 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client2?.mName}`;

export const getDOB2 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client2?.dob}`;

export const getMphone2 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client2?.mPhone}`;

export const getEmail2 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client2?.email}`;

export const getMarital2 = (client: ClientSummarySchema): string =>
  `${client.personalInfo.client2?.marital}`;
