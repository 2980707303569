/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import moment from "moment";
import _ from "lodash";
import Modal from "./Modal";
import styles from "./ClientResponse.module.scss";

import { fetchSummary, ServiceResponse, deleteUser } from "../lib/services";
import {
  getFullName1,
  getFullName2,
  getDOB1,
  getDOB2,
  getMphone1,
  getMphone2,
} from "../lib/utils";
import { ClientSummarySchema } from "../schemas/clientSummaryResponseSchema.json";

interface DetailsModalProps {
  showModal: boolean;
  onClose: () => void;
  onCloseRedirect: () => void;
  uid: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
      fontSize: "20",
    },
    root2: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    formControl: {
      margin: theme.spacing(3),
    },
  })
);

const DetailsModal: React.FC<DetailsModalProps> = (props) => {
  const classes = useStyles();

  const [summaryRes, setSummaryRes] = useState<
    ServiceResponse<ClientSummarySchema>
  >({ kind: "LOADING", value: null });

  const summary: ClientSummarySchema = summaryRes.value;

  const initiateData = (): void => {
    Promise.all([fetchSummary(props.uid)])
      .then(([client]) => {
        setSummaryRes(client);
      })
      .catch((error) => console.log("Error in promises :", { error }));
  };

  useEffect(() => {
    initiateData();
  }, [props.uid]);

  const [callMessage, setCallMessage] = useState<string>("");
  const [deleteButton, setDeleteButton] = useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState<string>("");

  const buttonClassname = clsx({
    [classes.buttonSuccess]: status === "success",
  });

  useEffect(() => {
    if (deleteButton === true) {
      setLoading(true);

      deleteUser(props.uid)
        .then((mess: any) => {
          if (!isNaN(mess)) {
            setCallMessage("");
            setStatus(mess);
          } else {
            setCallMessage(mess);
            setStatus("success");
            // initiateData()
          }

          setLoading(false);
          setDeleteButton(false);
        })
        .catch((error) => {
          console.log("useEffect catch :", error);
          setStatus("failed");
          setLoading(false);
          setDeleteButton(false);
        });
    }
  }, [deleteButton]);

  if (summaryRes.kind === "ERROR" || summaryRes.kind === "LOADING") {
    return <span>Error</span>;
  }

  let severity: any;
  let alertTitle = "";
  let alertMessage = "";

  switch (status) {
    case "":
      severity = "info";
      alertTitle = "User Management";
      alertMessage = `If you choose to delete this user then all records will be removed from the database and cannot be recovered.`;
      break;

    case "success":
      severity = "success";
      alertTitle = "Records deleted";
      alertMessage = `Sucessfully deleted the selected record, you will not be able to recover data.`;
      break;

    case "failed":
      severity = "error";
      alertTitle = "Failed to delete user";
      alertMessage = `Unknown error occured. Please contact helpdesk for assistance.`;
      break;

    case "401":
      severity = "error";
      alertTitle = "Failed to delete user";
      alertMessage = `You are not authorised to delete users. Please contact helpdesk for assistance.`;
      break;

    case "500":
      severity = "error";
      alertTitle = "Failed to delete user";
      alertMessage = `Server side error 500, this indicates that the server encountered an unexpected condition that prevented it from fulfilling the request. Please contact helpdesk for assistance.`;
      break;

    default:
      severity = "error";
      alertTitle = "Failed to delete user";
      alertMessage = `Unknown error occured. Please contact helpdesk for assistance.`;
      break;
  }

  const renderInsertedAccount = (status: string): JSX.Element | undefined => {
    if (status === "success") {
      return (
        <>
          <br />
          <br />
          <span style={{ fontSize: "13px", fontWeight: "bold" }}>
            Deletion request processed:{" "}
            <i style={{ fontWeight: "bold" }}>
              {JSON.parse(callMessage)[3].status}
            </i>
          </span>
          <br />
          <span style={{ fontSize: "12px" }}>
            User id:{" "}
            <i style={{ fontWeight: "bold" }}>
              {JSON.parse(callMessage)[0].userid}
            </i>
          </span>
          <br />
          <span style={{ fontSize: "12px" }}>
            User login:{" "}
            <i style={{ fontWeight: "bold" }}>
              {JSON.parse(callMessage)[0].status}
            </i>
          </span>
          <br />
          <span style={{ fontSize: "12px" }}>
            Client data:{" "}
            <i style={{ fontWeight: "bold" }}>
              {JSON.parse(callMessage)[1].status}
            </i>
          </span>
          <br />
          <span style={{ fontSize: "12px" }}>
            Change history:{" "}
            <i style={{ fontWeight: "bold" }}>
              {JSON.parse(callMessage)[2].status}
            </i>
          </span>
        </>
      );
    }
  };

  return (
    <Modal showModal={props.showModal} onClose={props.onClose}>
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginTop: "15px" }}
          >
            <Alert severity={severity}>
              <AlertTitle style={{ fontSize: "15px", fontWeight: "bold" }}>
                {alertTitle}
              </AlertTitle>
              <span style={{ fontSize: "12px" }}>{alertMessage}</span>
              {renderInsertedAccount(status)}
            </Alert>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4 style={{ color: "#FE9A2E" }}>Primary Client (Main)</h4>
            <div style={{ marginBottom: "3px" }}>
              <b>Name</b>: <br />
              {getFullName1(summary)}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Date of Birth</b>: <br />
              {moment(getDOB1(summary)).format("MMMM D, YYYY")}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Mobile Phone</b>: <br />
              {getMphone1(summary)}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <h4 style={{ color: "#FE9A2E" }}>Secondary Client (Partner)</h4>
            <div style={{ marginBottom: "3px" }}>
              <b>Name</b>: <br />
              {getFullName2(summary)}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Date of Birth</b>: <br />
              {moment(getDOB2(summary)).format("MMMM DD, YYYY")}
            </div>
            <div style={{ marginBottom: "3px" }}>
              <b>Mobile Phone</b>: <br />q{getMphone2(summary)}
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginBottom: "8px" }}
          >
            {/* TODO change flow so that only an indicator of a valid token is present   */}
            {((): JSX.Element | undefined => {
              return (
                <div className={classes.root2}>
                  <div className={classes.wrapper}>
                    <Fab
                      aria-label="upload"
                      color="secondary"
                      className={buttonClassname}
                      onClick={(): void => {
                        setDeleteButton(true);
                      }}
                    >
                      {status === "success" ? (
                        <CheckIcon />
                      ) : (
                        <DeleteForeverRoundedIcon
                          color="inherit"
                          fontSize="large"
                        />
                      )}
                    </Fab>
                    {loading && (
                      <CircularProgress
                        size={68}
                        className={classes.fabProgress}
                      />
                    )}
                  </div>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={buttonClassname}
                      disabled={loading}
                      onClick={(): void => {
                        setDeleteButton(true);
                      }}
                    >
                      Delete All records
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              );
            })()}

            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.secondaryButton}
                onClick={(): void => {
                  setLoading(false);
                  setDeleteButton(false);
                  setCallMessage("");

                  if (status === "success") {
                    props.onCloseRedirect();
                  }

                  props.onClose();
                  setStatus("");
                }}
              >
                Exit
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default DetailsModal;
