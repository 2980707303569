import Ajv from "ajv";
import Cookies from "js-cookie";
import clientSummaryResponseSchemaJson, {
  ClientSummarySchema,
} from "../schemas/clientSummaryResponseSchema.json";

const ajv = new Ajv({
  allErrors: true,
  removeAdditional: "all",
  // JSON Schema defaults could be used
  // https://json-schema.org/understanding-json-schema/reference/generic.html#annotations
  // useDefaults: true,
  schemas: [clientSummaryResponseSchemaJson],
});

const validateSummaryResponse = ajv.getSchema(
  "clientSummaryResponseSchema.json"
);

const _fetch = (path: string, init: RequestInit = {}): any => {
  const token = Cookies.get("token");

  return fetch(process.env.REACT_APP_API_URL + path, {
    ...init,
    referrer: window.location.href,
    headers: {
      ...init.headers,
      Authorization: `Bearer ${token}`,
    },
  });
};

let message = "";

export type ServiceResponse<T> =
  | { kind: "SUCCESS"; value: T }
  | { kind: "ERROR"; errors: Ajv.ErrorObject[]; value: null }
  | { kind: "LOADING"; value: any };

export const fetchSummary = (
  uid: string
): Promise<ServiceResponse<ClientSummarySchema>> =>
  _fetch(`client/${uid}/clientSummary`)
    .then((res: { json: () => any }) => res.json())
    .then((summaryResponse: any) => {
      const errors = validateSummaryResponse.errors as Ajv.ErrorObject[];
      const isValid = validateSummaryResponse(summaryResponse);

      if (!isValid) {
        return { kind: "ERROR" as const, errors };
      }

      return { kind: "SUCCESS" as const, value: summaryResponse.clientSummary };
    })
    .catch((error: any) => ({
      kind: "ERROR" as const,
      errors: error,
      value: null,
    }));

export const deleteUser = (uid: string): Promise<string> =>
  _fetch(`users/${uid}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ uid }),
  })
    .then((res: any) => {
      if (res.status !== 200) {
        throw new Error(res.status);
      }

      return res.json();
    })
    .then((res: any) => {
      message = JSON.stringify(res);

      return message;
    })
    .catch((error: Error) => {
      console.log("caught :", error.message);
      throw error;
    });
